import { ActionsType, AuthStateType, SessionTypes } from "../types";
import { getActiveWorkspace, jwtDecode, redirectToVendor, setActiveWorkspace, } from '../utils';

const initialReducer = (state: AuthStateType, action: ActionsType): AuthStateType => {
  if (action.type === SessionTypes.INITIAL) {
    let exp: number | undefined;
    try {
      const token = jwtDecode(action.payload.session.token);
      exp = token ? token.exp : 5;
    } catch (err) {
      // noop
    }

    let activeWorkspace = -1;
    if (action.payload.isAuthenticated) {
      if (action.payload.session.account.accountRole === 'WORKSPACE_ADMIN') {
        // do we have a stored one?
        const activeFromLocalStorage = getActiveWorkspace();
        if (activeFromLocalStorage !== -1) {
          activeWorkspace = activeFromLocalStorage;
        } else {
          const { defaultWorkspace, } = action.payload.session.account.workspaceSettings;
          activeWorkspace = action.payload.session.workspaces.findIndex((i) => i.id === defaultWorkspace);
        }
      } else if (action.payload.session.account.accountRole === 'RECRUITER') {
        activeWorkspace = 0;
      }
    }

    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      isFailedToFetch: !!action.payload.isFailedToFetch,

      account: action.payload.session.account,
      appConfig: action.payload.session.appConfig,
      activeWorkspace,
      workspaces: action.payload.session.workspaces,
      sessionType: action.payload.session.sessionType,
      token: action.payload.session.token,
      exp,
    };
  }
  return { ...state, };
}

const loginReducer = (state: AuthStateType, action: ActionsType): AuthStateType => {
  if (action.type === SessionTypes.LOGIN) {
    let exp: number | undefined;
    try {
      const token = jwtDecode(action.payload.session.token);
      exp = token ? token.exp : 5;
    } catch (err) {
      // noop
    }

    let activeWorkspace = -1;
    if (action.payload.session.account.accountRole === 'WORKSPACE_ADMIN') {
      // do we have a stored one?
      const activeFromLocalStorage = getActiveWorkspace();
      if (activeFromLocalStorage !== -1) {
        activeWorkspace = activeFromLocalStorage;
      } else {
        const { defaultWorkspace, } = action.payload.session.account.workspaceSettings;
        activeWorkspace = action.payload.session.workspaces.findIndex((i) => i.id === defaultWorkspace);
      }
    } else if (action.payload.session.account.accountRole === 'RECRUITER') {
      activeWorkspace = 0;
    }

    return {
      ...state,
      isAuthenticated: true,
      account: action.payload.session.account,
      activeWorkspace,
      workspaces: action.payload.session.workspaces,
      appConfig: action.payload.session.appConfig,
      sessionType: action.payload.session.sessionType,
      token: action.payload.session.token,
      exp,
    };
  }
  return { ...state, };
}

const loginUniversalReducer = (state: AuthStateType, action: ActionsType): AuthStateType => {
  if (action.type === SessionTypes.LOGIN_UNIVERSAL) {
    // let exp: number | undefined;
    try {
      // const token = jwtDecode(action.payload.session.token);
      // exp = token ? token.exp : 5;
    } catch (err) {
      // noop
    }

    console.log('reload here');


    redirectToVendor(action.payload.session)
    // action.payload.session.appConfig.appHost

    // window.location.href = 'https://google.com'

    // let activeWorkspace = -1;
    // if (action.payload.session.account.accountRole === 'WORKSPACE_ADMIN') {
    //   // do we have a stored one?
    //   const activeFromLocalStorage = getActiveWorkspace();
    //   if (activeFromLocalStorage !== -1) {
    //     activeWorkspace = activeFromLocalStorage;
    //   } else {
    //     const { defaultWorkspace, } = action.payload.session.account.workspaceSettings;
    //     activeWorkspace = action.payload.session.workspaces.findIndex((i) => i.id === defaultWorkspace);
    //   }
    // } else if (action.payload.session.account.accountRole === 'RECRUITER') {
    //   activeWorkspace = 0;
    // }

    return {
      ...state,
      // isAuthenticated: true,
      // account: action.payload.session.account,
      // activeWorkspace: -1,
      // workspaces: action.payload.session.workspaces,
      // appConfig: action.payload.session.appConfig,
      // sessionType: action.payload.session.sessionType,
      // token: action.payload.session.token,
      // exp,
    };
  }
  return { ...state, };
}

const logoutReducer = (state: AuthStateType, action: ActionsType): AuthStateType => {
  if (action.type === SessionTypes.LOGOUT) {
    let exp: number | undefined;
    try {
      const token = jwtDecode(action.payload.session.token);
      exp = token ? token.exp : 5;
    } catch (err) {
      // noop
    }
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      isFailedToFetch: !!action.payload.isFailedToFetch,

      account: action.payload.session.account,
      appConfig: action.payload.session.appConfig,
      workspaces: action.payload.session.workspaces,
      activeWorkspace: -1,
      sessionType: action.payload.session.sessionType,
      token: action.payload.session.token,
      exp,
    };
  }
  return { ...state, };
}

const registerReducer = (state: AuthStateType, action: ActionsType): AuthStateType => {
  if (action.type === SessionTypes.REGISTER) {
    let exp: number | undefined;
    try {
      const token = jwtDecode(action.payload.session.token);
      exp = token ? token.exp : 5;
    } catch (err) {
      // noop
    }

    let activeWorkspace = -1;
    if (action.payload.session.account.accountRole === 'WORKSPACE_ADMIN') {
      // do we have a stored one?
      const activeFromLocalStorage = getActiveWorkspace();
      if (activeFromLocalStorage !== -1) {
        activeWorkspace = activeFromLocalStorage;
      } else {
        const { defaultWorkspace, } = action.payload.session.account.workspaceSettings;
        activeWorkspace = action.payload.session.workspaces.findIndex((i) => i.id === defaultWorkspace);
      }
    } else if (action.payload.session.account.accountRole === 'RECRUITER') {
      activeWorkspace = 0;
    }

    return {
      ...state,
      isAuthenticated: true,
      account: action.payload.session.account,
      appConfig: action.payload.session.appConfig,
      activeWorkspace,
      workspaces: action.payload.session.workspaces,
      sessionType: action.payload.session.sessionType,
      token: action.payload.session.token,
      exp,
    };
  }
  return { ...state, };
}

const updateCurrentAccountReducer = (state: AuthStateType, action: ActionsType): AuthStateType => {
  if (action.type === SessionTypes.UPDATE_ACCOUNT) {
    return {
      ...state,
      account: action.payload.userAccount,
    };
  }
  return { ...state, };
}

const updateCurrentAppConfigReducer = (state: AuthStateType, action: ActionsType): AuthStateType => {
  if (action.type === SessionTypes.UPDATE_APP_CONFIG) {
    return {
      ...state,
      appConfig: action.payload.appConfig,
    };
  }
  return { ...state, };
}

const updateCurrentAppConfigWorkspacesReducer = (state: AuthStateType, action: ActionsType): AuthStateType => {
  if (action.type === SessionTypes.UPDATE_APP_WORKSPACES) {
    return {
      ...state,
      workspaces: action.payload.workspaces,
    };
  }
  return { ...state, };
}

const setCurrentWorkspace = (state: AuthStateType, action: ActionsType): AuthStateType => {
  if (action.type === SessionTypes.SET_ACTIVE_WORKSPACE) {
    console.log('updating current workspace to', action.payload.workspaceIndex)

    setActiveWorkspace(`${action.payload.workspaceIndex}`)

    return {
      ...state,
      activeWorkspace: action.payload.workspaceIndex,
    };
  }
  return { ...state, };
}

const auth = {
  reducers: {
    [SessionTypes.INITIAL]: initialReducer,
    [SessionTypes.LOGIN]: loginReducer,
    [SessionTypes.LOGIN_UNIVERSAL]: loginUniversalReducer,
    [SessionTypes.LOGOUT]: logoutReducer,
    [SessionTypes.REGISTER]: registerReducer,
    [SessionTypes.UPDATE_ACCOUNT]: updateCurrentAccountReducer,
    [SessionTypes.UPDATE_APP_CONFIG]: updateCurrentAppConfigReducer,
    [SessionTypes.UPDATE_APP_WORKSPACES]: updateCurrentAppConfigWorkspacesReducer,
    [SessionTypes.SET_ACTIVE_WORKSPACE]: setCurrentWorkspace,
  }
};

export default auth;
