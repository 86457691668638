export const HOST_API_KEY = process.env.REACT_APP_HOST_API_KEY || '';

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  H_MOBILE: 64,
  H_MAIN_DESKTOP: 88,
  H_DASHBOARD_DESKTOP: 92,
  H_DASHBOARD_DESKTOP_OFFSET: 92 - 32,

  H_DESKTOP: 80,
  H_DESKTOP_OFFSET: 80 - 16,
};

export const HEADER_WORKSPACE = {
  H_MOBILE: 108,
  H_MAIN_DESKTOP: 88,
  H_DASHBOARD_DESKTOP: 132,
  H_DASHBOARD_DESKTOP_OFFSET: 132 - 32,

  H_WORKSPACE_TOOLBAR: 40,
  H_NAV_TOOLBAR: 80,
  H_DESKTOP: 120,
  H_DESKTOP_OFFSET: 120 - 16,
};


export const NAV = {
  W_BASE: 260,
  W_DASHBOARD: 280,
  W_DASHBOARD_MINI: 88,
  //
  H_DASHBOARD_ITEM: 48,
  H_DASHBOARD_ITEM_SUB: 36,
  //
  H_DASHBOARD_ITEM_HORIZONTAL: 32,

  W_VERTICAL: 280,
  W_MINI: 88,
};

export const ICON = {
  NAV_ITEM: 24,
  NAV_ITEM_HORIZONTAL: 22,
  NAV_ITEM_MINI: 22,
};
