import { AppConfigWorkspace } from "@piccadilly-cloud/connect-platform-services";
import { useMemo } from "react";
import { useSessionContext } from "src/contexts/session/useSessionContext";

const defaultWorkspace: AppConfigWorkspace = {
  id: "",
  vendor: "",
  name: "",
  createdAt: new Date(),
  updatedAt: new Date(),
  deleted: false,
  departments: [],
  defaultCompanyProfile: "",
}

interface ReturnType {
  index: number;
  workspace: AppConfigWorkspace;
}

export function useWorkspace(): ReturnType {
  const session = useSessionContext();

  const data = useMemo(() => {
    let index = -1;
    if (session.activeWorkspace !== -1){
      index = session.activeWorkspace;
    }

    if (session.workspaces.length < index + 1) {
      index = -1;
    }

    if (index === -1) {
      return {
        index,
        workspace: defaultWorkspace,
      }
    }

    const workspace = session.workspaces[index];
    return {
      index,
      workspace,
    }

  }, [session])

  return data;
}