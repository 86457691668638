import { lazy } from 'react';
import { useRoutes } from 'react-router-dom';
//
import { Session } from '@piccadilly-cloud/connect-platform-services';
import { isUniversalLogin } from 'src/contexts/session/utils';
import AuthClassicLayout from 'src/layouts/auth/classic';
import SessionGuard from 'src/contexts/session/guards/session-guard';
import { mainRoutes } from './main';

import { adminRoutes, } from './admin';
import { workspaceAdminRoutes } from './workspace-admin';
import { recruiterRoutes } from './recruiter';
import { candidateRoutes } from './candidate';
import { universalLoginRoutes } from './universalLogin';
import { vendorAdminRoutes } from './vendorAdmin';



const Page404 = lazy(() => import('src/pages/404'));



// ----------------------------------------------------------------------

export default function Router(props: {
  session: Session,
}) {
  const { session, } = props;

  const admin = [
    ...((session.sessionType === 'AUTHENTICATED' && session.account.accountRole === 'ADMIN') ? adminRoutes : []),
  ];
  const workspaceAdmin = [
    ...((session.sessionType === 'AUTHENTICATED' && session.account.accountRole === 'WORKSPACE_ADMIN') ? workspaceAdminRoutes : []),
  ];
  const recruiter = [
    ...((session.sessionType === 'AUTHENTICATED' && session.account.accountRole === 'RECRUITER') ? recruiterRoutes : []),
  ];
  const vendorAdmin = [
    ...((session.sessionType === 'AUTHENTICATED' && session.account.accountRole === 'VENDOR_ADMIN') ? vendorAdminRoutes : [])
  ]
  const candidate = [
    ...((session.sessionType === 'AUTHENTICATED' && session.account.accountRole === 'CANDIDATE') ? candidateRoutes : []),
  ];



  let routes = [
    ...mainRoutes,
    ...admin,
    ...workspaceAdmin,
    ...recruiter,
    ...candidate,
    ...vendorAdmin,
    {
      path: '*', element: (
        <SessionGuard>
          <AuthClassicLayout>
            <Page404 />
          </AuthClassicLayout>
        </SessionGuard>
      )
    },
  ];
  if (isUniversalLogin()) {
    routes = [
      ...universalLoginRoutes,
      {
        path: '*', element: (
          <SessionGuard>
            <AuthClassicLayout>
              <Page404 />
            </AuthClassicLayout>
          </SessionGuard>
        )
      },
    ]
  }


  return useRoutes(routes);
}
