// i18n
import 'src/locales/i18n';

// scrollbar
import 'simplebar-react/dist/simplebar.min.css';

// image
import 'react-lazy-load-image-component/src/effects/blur.css';

// carousel
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// ----------------------------------------------------------------------

// routes
import Router from 'src/routes/sections';
// theme
import ThemeProvider from 'src/theme/mui';
import 'src/components-piccadilly/pdf/styles.css';
// locales
import { LocalizationProvider } from 'src/locales';
// hooks
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
// components
import ProgressBar from 'src/components/progress-bar';
import { MotionLazy } from 'src/components/animate/motion-lazy';
import { SettingsProvider, SettingsDrawer } from 'src/components/settings';
import { QueryClientProvider, QueryClient, } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { SnackbarProvider } from 'src/components/snackbar';
// auth
import { useSessionContext } from './contexts/session/useSessionContext';
import SessionGuard from './contexts/session/guards/session-guard';

// ----------------------------------------------------------------------

const queryClient = new QueryClient();

export default function App() {
  const session = useSessionContext();
  useScrollToTop();

  return (
    <QueryClientProvider client={queryClient}>
      <LocalizationProvider>
        <SettingsProvider
          defaultSettings={{
            themeMode: 'light', // 'light' | 'dark'
            themeDirection: 'ltr', //  'rtl' | 'ltr'
            themeContrast: 'bold', // 'default' | 'bold'
            themeLayout: 'mini', // 'vertical' | 'horizontal' | 'mini'
            themeColorPresets: 'default', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
            themeStretch: true,
          }}
        >
          <ThemeProvider>
            <SnackbarProvider>
              <MotionLazy>
                <SessionGuard>
                  <SettingsDrawer />
                  <ProgressBar />
                  <Router session={session} />
                  {process.env.REACT_APP_DEBUG_QUERY === 'true' && (
                    <ReactQueryDevtools position='bottom-right' />
                  )}
                </SessionGuard>
              </MotionLazy>
            </SnackbarProvider>
          </ThemeProvider>
        </SettingsProvider>
      </LocalizationProvider>
    </QueryClientProvider>
  );
}
