import { lazy } from 'react';
import { Outlet } from 'react-router-dom';

// layouts
import AuthClassicLayout from 'src/layouts/auth/classic';

// components
import SessionGuard from 'src/contexts/session/guards/session-guard';


const Root = lazy(() => import('src/pages/root'));
const ForgotPasswordPage = lazy(() => import('src/pages/auth/forgot-password-universal'));
const Page404 = lazy(() => import('src/pages/404'));
const Page403 = lazy(() => import('src/pages/403'));

const core = {
  element: (
    <SessionGuard>
      <AuthClassicLayout>
        <Outlet />
      </AuthClassicLayout>
    </SessionGuard>
  ),
  children: [
    { path: '', element: <Root /> },
    { path: '/login', element: <Root /> },
    { path: '/forgot-password', element: (<ForgotPasswordPage />), },
    { path: '404', element: <Page404 /> },
    { path: '403', element: <Page403 /> },
  ],
};


export const universalLoginRoutes = [
  core,
]