import TagManager from 'react-gtm-module';
import { ActionsType, EventStateType, EventTypes } from '../types';

const initialReducer = (state: EventStateType, action: ActionsType): EventStateType => {
  if (action.type === EventTypes.INITIAL) {
    return {
      userId: action.payload.session.account.id,
      vendorId: action.payload.session.appConfig.vendor,
      workspaceId: action.payload.workspaceId || '',
      eventChain: []
    }
  }
  return { ...state, };
}

const triggerEvent = (state: EventStateType, action: ActionsType): EventStateType => {
  if (action.type === EventTypes.TRIGGER_EVENT) {
    const page = document.title;

    TagManager.dataLayer({
      dataLayer: {
        page,
        event: action.payload.event,
        userId: state.userId,
        vendorId: state.vendorId,
        workspaceId: state.workspaceId,
        eventProps: action.payload.eventProps,
      }
    })

    return {
      ...state,
    }
  }

  return { ...state, }
}

const setCurrentWorkspace = (state: EventStateType, action: ActionsType): EventStateType => {
  if (action.type === EventTypes.SET_ACTIVE_WORKSPACE) {
    return {
      ...state,
      workspaceId: action.payload.workspaceId,
    };
  }
  return { ...state, };
}

const events = {
  reducers: {
    [EventTypes.INITIAL]: initialReducer,
    [EventTypes.TRIGGER_EVENT]: triggerEvent,
    [EventTypes.SET_ACTIVE_WORKSPACE]: setCurrentWorkspace,
  }
};

export default events;