import { EventTypes } from '../types';

const triggerEvent = (event: string, eventProps: Object) => async (dispatch: React.Dispatch<any>) => {
  const payload = { event, eventProps };
  dispatch({ type: EventTypes.TRIGGER_EVENT, payload, })
}

const setCurrentWorkspace = (index: number) => async (dispatch: React.Dispatch<any>) => {
  const payload = { workspaceIndex: index, }
  dispatch({ type: EventTypes.SET_ACTIVE_WORKSPACE, payload, });
}

const eventActions = {
  triggerEvent,
  setCurrentWorkspace,
};

export default eventActions;