import { Session } from "@piccadilly-cloud/connect-platform-services";


export type ActionMapType<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
  ? {
    type: Key;
  }
  : {
    type: Key;
    payload: M[Key];
  };
};

export type EventStateType = {
  userId: string;
  vendorId: string;
  workspaceId: string;
  eventChain: string[];
};

export enum EventTypes {
  INITIAL = 'event/INITIAL',
  TRIGGER_EVENT = 'event/TRIGGER_EVENT',
  SET_ACTIVE_WORKSPACE = 'event/SET_WORKSPACE'
}

type EventPayload = {
  [EventTypes.INITIAL]: {
    session: Session;
    workspaceId: string;
  };
  [EventTypes.TRIGGER_EVENT]: {
    event: string;
    eventProps: Object;
  };
  [EventTypes.SET_ACTIVE_WORKSPACE]: {
    workspaceId: string;
  }
};

export type ActionsType = ActionMapType<EventPayload>[keyof ActionMapType<EventPayload>];

// ----------------------------------------------------------------------

export type EventContextType = {
  session: Session;
  workspaceId: string;
  eventChain: string[];

  triggerEvent: (event: string, eventProps: Object) => void;
  setCurrentWorkspace: (workspaceId: string) => void;
};