import {
  CircularProgress
} from '@mui/material';

export default function LoadingInline(props: {
  color?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | 'inherit'
}) {
  const {
    color,
  } = props;

  return (
    <CircularProgress color={color || 'primary'} />
  );
}
