// @mui
import { Theme, SxProps, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
// components
import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

type Props = {
  sx?: SxProps<Theme>;
};

export default function SupportButton({ sx }: Props) {
  const theme = useTheme();

  return (
    <Box>
      <IconButton
        aria-label="support"
        component="a"
        target="_blank"
        href={process.env.REACT_APP_ZENDESK_HC_URL}
        sx={{
          p: 0,
          color: theme.palette.grey[500],
          '&:hover': {
            border: 0,
            color: theme.palette.primary.main,
          }
        }}
      >
        <Iconify icon="mdi:help-circle" width={28} />
      </IconButton>
    </Box>
  );
}
