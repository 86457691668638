import { palette } from '../palette';
// import { customShadows } from '../custom-shadows';

// ----------------------------------------------------------------------

export function contrast(contrastBold: boolean, mode: 'light' | 'dark') {
  const theme = {
    ...(contrastBold &&
      mode === 'light' && {
        palette: {
          background: {
            default: '#fff',
          },
        },
      }),
  };

  const components = {
    ...(contrastBold && {
      MuiCard: {
        styleOverrides: {
          root: {
            boxShadow: 'unset',
            border: `1px solid ${palette(mode).grey[200]}`,
          },
        },
      },
    }),
  };

  return {
    theme,
    components,
  };
}
