import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
// components
import { LoadingScreen } from 'src/components/loading-screen';
import { paths } from 'src/routes/paths';
import { useSessionContext } from '../useSessionContext';
//

// ----------------------------------------------------------------------

type VendorAdminGuardProps = {
  children: ReactNode;
};

export default function VendorAdminGuard({ children }: VendorAdminGuardProps) {
  const { isAuthenticated, isInitialized, account } = useSessionContext();

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    return <Navigate to={paths.auth.login} />;
  }

  if (account.accountRole !== 'VENDOR_ADMIN') {
    return <Navigate to={paths.notAuthorized} />
  }

  return <>{children}</>;
}