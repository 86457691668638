import { createContext, useCallback, useEffect, useReducer } from "react";
import useLogger from "src/utils/useReducerLogger";
import { useSessionContext } from '../session/useSessionContext';
import eventActions from './actions/events';
import events from './reducers/events';
import { ActionsType, EventContextType, EventStateType, EventTypes } from "./types";

const initialState: EventStateType = {
  userId: '',
  vendorId: '',
  workspaceId: '',
  eventChain: [],
};

const reducer = (state: EventStateType, action: ActionsType) => {
  const reducers = {
    ...events.reducers,
  }
  if (reducers[action.type]) {
    return reducers[action.type](state, action);
  }
  return state;
};

// ----------------------------------------------------------------------

export const EventContext = createContext<EventContextType | null>(null);

// ----------------------------------------------------------------------

type EventProviderProps = {
  children: React.ReactNode;
};

export function EventProvider({ children }: EventProviderProps) {
  const session = useSessionContext();
  const [state, dispatch] = useReducer(
    // eslint-disable-next-line react-hooks/rules-of-hooks
    process.env.REACT_APP_DEBUG_REDUX === 'true' ? useLogger(reducer) : reducer, initialState
  );

  const initialize = useCallback(async () => {
    const workspaceId = session.activeWorkspace && session.activeWorkspace !== -1 ? session.workspaces[session.activeWorkspace].id : '';
    dispatch({
      type: EventTypes.INITIAL,
      payload: {
        session,
        workspaceId
      },
    });
  }, [session])

  useEffect(() => {
    initialize();
  }, [initialize]);

  return (
    <EventContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        ...state,
        userid: state.userId,
        vendorid: state.vendorId,
        workspaceid: state.workspaceId,
        eventChain: state.eventChain,

        triggerEvent: (event: string, eventProps: Object) => eventActions.triggerEvent(event, eventProps)(dispatch),
        setCurrentWorkspace: (workspaceIndex: number) => eventActions.setCurrentWorkspace(workspaceIndex)(dispatch),
      }}
    >
      {children}
    </EventContext.Provider>
  );
}