import { alpha } from '@mui/material/styles';
// ----------------------------------------------------------------------
export type ColorSchema = 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error';
declare module '@mui/material/styles/createPalette' {
  interface TypeBackground {
    neutral: string;
  }
  interface SimplePaletteColorOptions {
    lighter: string;
    darker: string;
  }
  interface PaletteColor {
    lighter: string;
    darker: string;
  }
}

// SETUP COLORS

const GREY = {
  0: "#f4f5f6",
  50: "#f4f5f6",
  100: "#e9eaea",
  200: "#d3d4d5",
  300: "#bdbfc1",
  400: "#a7a9ac",
  500: "#919497",
  600: "#7a7f82",
  700: "#64696d",
  800: "#4e5459",
  900: "#383e44",
};

const greyLight = {
  50: "#f4f5f6",
  100: "#e9eaea",
  200: "#d3d4d5",
  300: "#bdbfc1",
  400: "#a7a9ac",
  500: "#919497",
  600: "#7a7f82",
  700: "#64696d",
  800: "#4e5459",
  900: "#383e44",
};

// const greyLight = {
//   50: "#a6b2bc",
//   100: "#a6b2bc",
//   200: "#8899a5",
//   300: "#8899a5",
//   400: "#6A7F8F",
//   500: "#6A7F8F",
//   600: "#556672",
//   700: "#556672",
//   800: "#404c56",
//   900: "#404c56",
// };

const PRIMARY = {
  lighter: '#EAF1F7',
  light: '#98BBD5',
  main: '#3278AB',
  dark: '#286089',
  darker: '#1E4867',
  contrastText: '#FFFFFF',
};

const SECONDARY = {
  lighter: '#FCF6E7',
  light: "#F1D489",
  main: "#E2A813",
  dark: "#B5860F",
  darker: '#88650B',
  contrastText: '#1e4867',
};

const INFO = {
  lighter: '#EEF1F4',
  light: "#ACB7C8",
  main: "#596F91",
  dark: "#475974",
  darker: '#354357',
  contrastText: '#eef1f4',
};

const SUCCESS = {
  lighter: '#EBF7F0',
  light: "#9DD5B2",
  main: "#3BAB65",
  dark: "#2F8951",
  darker: '#23673D',
  contrastText: '#E8F2E8',
};

const WARNING = {
  lighter: '#FBF2E9',
  light: "#EABC92",
  main: "#D57925",
  dark: "#AA611E",
  darker: '#804916',
  contrastText: '#faf1e7',
};

const ERROR = {
  lighter: '#F7EBEA',
  light: "#D59998",
  main: "#AB3432",
  dark: "#892A28",
  darker: '#671F1E',
  contrastText: '#f9e8e7',
};

const COMMON = {
  common: {
    black: '#22292F',
    white: '#FFFFFF',
  },
  primary: PRIMARY,
  secondary: SECONDARY,
  info: INFO,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  grey: GREY,
  divider: greyLight[200],
  action: {
    hover: alpha(PRIMARY.main, 0.08),
    selected: alpha(PRIMARY.main, 0.16),
    disabled: alpha(PRIMARY.main, 0.8),
    disabledBackground: alpha(PRIMARY.main, 0.24),
    focus: alpha(PRIMARY.main, 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
    // hover: alpha(GREY[500], 0.08),
    // selected: alpha(GREY[500], 0.16),
    // disabled: alpha(GREY[500], 0.8),
    // disabledBackground: alpha(GREY[500], 0.24),
    // focus: alpha(GREY[500], 0.24),
    // hoverOpacity: 0.08,
    // disabledOpacity: 0.48,
  },
};

export function palette(mode: 'light' | 'dark') {
  const light = {
    ...COMMON,
    mode: 'light',
    text: {
      primary: GREY[900],
      secondary: GREY[700],
      disabled: GREY[500],
    },
    background: {
      paper: '#FFFFFF',
      default: '#f4f5f6',
      neutral: GREY[200],
    },
    action: {
      ...COMMON.action,
      active: GREY[700],
    },
  };

  const dark = {
    ...COMMON,
    mode: 'dark',
    text: {
      primary: '#FFFFFF',
      secondary: GREY[500],
      disabled: GREY[600],
    },
    background: {
      paper: '#222b36',
      default: '#171c24',
      neutral: alpha(GREY[500], 0.12),
    },
    action: {
      ...COMMON.action,
      active: GREY[500],
    },
  };

  return mode === 'light' ? light : dark;
}
