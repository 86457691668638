import { Box, Typography, useTheme } from "@mui/material";
import { paths } from "src/routes/paths";

export default function Footer() {
  const theme = useTheme();
  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignContent="center" alignItems="center" rowGap={1} sx={{ pt: 5, }}>

      <Box display="flex" flexDirection="row" columnGap={2}>
        <Typography
          sx={{
            color: theme.palette.primary.main,
            fontSize: '12px',
            fontWeight: 600,
            cursor: 'pointer',
            '&:hover': {
              textDecoration: 'underline',
            },
          }}
          component="a"
          target="_blank"
          href={paths.termsOfService}
        >
          Terms & Conditions
        </Typography>
        <Typography
          sx={{
            color: theme.palette.primary.main,
            fontSize: '12px',
            fontWeight: 600,
            cursor: 'pointer',
            '&:hover': {
              textDecoration: 'underline',
            }
          }}
          component="a"
          target="_blank"
          href={paths.privacyPolicy}
        >
          Privacy Policy
        </Typography>

      </Box>

      <Box sx={{ width: '247px', }}>
        <img alt="powered by piccadilly cloud" src="/logo/Powered_by_PiccadillyCloud.svg" />
      </Box>

      <Typography variant='caption' sx={{ color: theme.palette.grey[300] }}>
        ©2024 Piccadilly Cloud
      </Typography>
    </Box>
  )
}