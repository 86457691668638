import { AppConfig, AppConfigWorkspace, CandidateUserAccountRegistrationRequest, Session, SessionType, UserAccount, UserAccountInvitation, UserAccountRegistrationRequest } from "@piccadilly-cloud/connect-platform-services";


export type ActionMapType<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
  ? {
    type: Key;
  }
  : {
    type: Key;
    payload: M[Key];
  };
};

export type AuthStateType = {
  isAuthenticated: boolean;
  isInitialized: boolean;
  isFailedToFetch: boolean;

  account: UserAccount;
  appConfig: AppConfig;
  workspaces: AppConfigWorkspace[];
  activeWorkspace: number;
  sessionType: SessionType;
  token: string;
  exp?: number;
};

export enum SessionTypes {
  INITIAL = 'session/INITIAL',
  LOGIN = 'session/LOGIN',
  LOGIN_UNIVERSAL = 'session/LOGIN_UNIVERSAL',
  REGISTER = 'session/REGISTER',
  LOGOUT = 'session/LOGOUT',
  UPDATE_ACCOUNT = 'session/UPDATE_ACCOUNT',
  UPDATE_APP_CONFIG = 'session/UPDATE_APP_CONFIG',
  UPDATE_APP_WORKSPACES = 'session/UPDATE_APP_WORKSPACES',
  SET_ACTIVE_WORKSPACE = 'session/SET_ACTIVE_WORKSPACE',
}

type SessionPayload = {
  [SessionTypes.INITIAL]: {
    isAuthenticated: boolean;
    isFailedToFetch?: boolean;
    session: Session;
  };
  [SessionTypes.LOGIN]: {
    session: Session;
  };
  [SessionTypes.LOGIN_UNIVERSAL]: {
    session: Session;
  };
  [SessionTypes.LOGOUT]: {
    isAuthenticated: boolean;
    isFailedToFetch?: boolean;
    session: Session;
  };
  [SessionTypes.REGISTER]: {
    session: Session;
  };
  [SessionTypes.UPDATE_ACCOUNT]: {
    userAccount: UserAccount;
  }
  [SessionTypes.UPDATE_APP_CONFIG]: {
    appConfig: AppConfig;
  }
  [SessionTypes.UPDATE_APP_WORKSPACES]: {
    workspaces: AppConfigWorkspace[];
  }
  [SessionTypes.SET_ACTIVE_WORKSPACE]: {
    workspaceIndex: number;
  }
};

export type ActionsType = ActionMapType<SessionPayload>[keyof ActionMapType<SessionPayload>];

// ----------------------------------------------------------------------

export type SessionContextType = {
  method: 'jwt';
  isAuthenticated: boolean;
  isInitialized: boolean;
  isFailedToFetch: boolean;

  account: UserAccount;
  appConfig: AppConfig;
  workspaces: AppConfigWorkspace[];
  activeWorkspace: number;
  sessionType: SessionType;
  token: string;
  exp?: number;

  loginUniversal: (token: string, email: string, password: string, rememberMe: boolean, vendor: string) => Promise<void>;
  login: (token: string, email: string, password: string, rememberMe: boolean, vendor: string) => Promise<void>;
  loginPasscode: (token: string, passCode: string, vendor: string) => Promise<void>;
  logout: () => Promise<void>;
  register: (token: string, request: UserAccountRegistrationRequest, invitation: UserAccountInvitation) => Promise<void>;
  registerCandidate: (token: string, request: CandidateUserAccountRegistrationRequest) => Promise<Session>;

  updateCurrentAccount: (updatedAccount: UserAccount) => void;
  updateCurrentAppConfig: (updatedAccount: AppConfig) => void;
  updateCurrentAppConfigWorkspaces: (updatedWorkspaces: AppConfigWorkspace[]) => void;

  setCurrentWorkspace: (index: number) => void;
};
