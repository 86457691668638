// import LoadingScreenWithMessage from 'components/error-messages/PlatformFailure';
import React, { ReactNode } from 'react';
import services from '@piccadilly-cloud/connect-platform-services';
import { LoadingScreen } from 'src/components/loading-screen';
import { useSessionContext } from '../useSessionContext';

export default function SessionGuard(props: {
  children: ReactNode;
}) {
  const session = useSessionContext();
  const { children, } = props;

  if (session.isInitialized && session.isFailedToFetch) {
    services.alert.phoneHome.log(session, `session guard tripped\n${window.location.hostname}\n\n${window.navigator.userAgent}`)
    return (
      <LoadingScreen />
    );
  }

  if (!session.isInitialized) {
    return (
      <LoadingScreen />
    )
  }

  return (
    <>
      {children}
    </>
  );
}